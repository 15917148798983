<template>
  <div class="set-info-index">
    <el-form label-width="80px" :model="ruleForm" ref="ruleForm" :rules="rules">
      <el-form-item label="名字" prop="name">
        <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="ruleForm.account" style="width: 300px" disabled></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password" style="width: 300px" show-password></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input v-model="ruleForm.phone" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="所属学校" prop="school">
        <el-input v-model="ruleForm.school" style="width: 300px" disabled></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="img">
        <el-upload class="avatar-uploader" name="student_avatar" accept=".jpg, .jpeg, .png" :action="uploadUrl" :headers="headersParams" :show-file-list="false" :on-success="handleAvatarSuccess">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" style="color: #999; margin-top: -20px">(建议尺寸200*200，格式为jpg/jpeg/png)</div>
        </el-upload>
      </el-form-item>
      <el-form-item style="margin-top: 100px">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {},
  props: {},
  data() {
    return {
      uploadUrl: "/student_manage/upload",
      headersParams: {
        Authorization: localStorage.getItem("teacherToken"),
      },
      imageUrl: "",
      ruleForm: {
        name: "王明",
        account: "wangming01",
        password: "123456",
        phone: "16851514411",
        email: "468465@163.com",
        school: "厦门第一中学分校",
        img: "",
      },
      rules: {
        name: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
      },
    };
  },
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message.success("操作成功！");
          localStorage.setItem("personInfo", JSON.stringify(this.ruleForm));
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
  },
  mounted() {
    let res = localStorage.getItem("personInfo");
    if (res) this.ruleForm = JSON.parse(res);
  },
};
</script>

<style scoped lang="scss">
.set-info-index {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
